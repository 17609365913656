define("discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/composer-fields/topic-custom-field-composer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (or
          (and (not this.editingPost) this.isNew (not this.reply))
          (and this.editingPost this.editingFirstPost)
        )}}
    <TopicCustomFieldInput
      @fieldValue={{this.fieldValue}}
      @onChangeField={{this.onChangeField}}
    />
  {{/if}}
  */
  {
    "id": "v5JpF8Sf",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[[28,[37,3],[[30,0,[\"editingPost\"]]],null],[30,0,[\"isNew\"]],[28,[37,3],[[30,0,[\"reply\"]]],null]],null],[28,[37,2],[[30,0,[\"editingPost\"]],[30,0,[\"editingFirstPost\"]]],null]],null],[[[1,\"  \"],[8,[39,4],null,[[\"@fieldValue\",\"@onChangeField\"],[[30,0,[\"fieldValue\"]],[30,0,[\"onChangeField\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"or\",\"and\",\"not\",\"topic-custom-field-input\"]]",
    "moduleName": "discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/composer-fields/topic-custom-field-composer.hbs",
    "isStrictMode": false
  });
});