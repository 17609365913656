define("discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/edit-topic/topic-custom-field-edit-topic", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (and this.editingFirstPost this.canEditTitle)}}
        <TopicCustomFieldInput
          @fieldValue={{this.fieldValue}}
          @onChangeField={{this.onChangeField}}
        />
  {{/if}}
  
  */
  {
    "id": "uuXqeewL",
    "block": "[[[41,[28,[37,1],[[30,0,[\"editingFirstPost\"]],[30,0,[\"canEditTitle\"]]],null],[[[1,\"      \"],[8,[39,2],null,[[\"@fieldValue\",\"@onChangeField\"],[[30,0,[\"fieldValue\"]],[30,0,[\"onChangeField\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"and\",\"topic-custom-field-input\"]]",
    "moduleName": "discourse/plugins/discourse-topic-custom-fields/discourse/templates/connectors/edit-topic/topic-custom-field-edit-topic.hbs",
    "isStrictMode": false
  });
});