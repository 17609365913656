define("discourse/plugins/discourse-topic-custom-fields/discourse/connectors/composer-fields/topic-custom-field-composer", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/object/computed"], function (_exports, _component, _object, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicCustomFieldComposer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.alias)("siteSettings.topic_custom_field_name")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "composerModel", [(0, _computed.alias)("args.outletArgs.model")]))();
    #composerModel = (() => (dt7948.i(this, "composerModel"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "topic", [(0, _computed.alias)("composerModel.topic")]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "reply", [(0, _computed.alias)("composerModel.replyingToTopic")]))();
    #reply = (() => (dt7948.i(this, "reply"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "canEditTitle", [(0, _computed.alias)("composerModel.canEditTitle")]))();
    #canEditTitle = (() => (dt7948.i(this, "canEditTitle"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "isNew", [(0, _computed.alias)("composerModel.isNew")]))();
    #isNew = (() => (dt7948.i(this, "isNew"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "currentAction", [(0, _computed.alias)("composerModel.action")]))();
    #currentAction = (() => (dt7948.i(this, "currentAction"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "editingFirstPost", [(0, _computed.alias)("composerModel.editingFirstPost")]))();
    #editingFirstPost = (() => (dt7948.i(this, "editingFirstPost"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "editingPost", [(0, _computed.alias)("composerModel.editingPost")]))();
    #editingPost = (() => (dt7948.i(this, "editingPost"), void 0))();
    constructor() {
      super(...arguments);
      const isEditingFirstPost = this.currentAction === "edit" && this.editingFirstPost;
      const isNewTopicPost = this.isNew && !this.reply;
      if ((isNewTopicPost || isEditingFirstPost) && this.topic && this.topic[this.fieldName]) {
        // If the composer doesn’t already have the field set, set it:
        if (!this.composerModel[this.fieldName]) {
          const processUrl = this.transformToUrl(this.topic[this.fieldName]);
          this.composerModel.set(this.fieldName, processUrl);
          this.fieldValue = processUrl;
        }
      }
    }
    isValidProcessId(str) {
      const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/?$/i;
      return uuidRegex.test(str);
    }
    transformToUrl(value) {
      if (!value) return null;

      // Clean up the value
      value = value.trim();

      // If it's already a valid process ID
      if (this.isValidProcessId(value)) {
        return `https://www.fabublox.com/process-editor/${value}`;
      }

      // If it's a full URL, extract and validate the process ID
      const parts = value.split("/");
      const lastPart = parts[parts.length - 1];
      if (this.isValidProcessId(lastPart)) {
        return `https://www.fabublox.com/process-editor/${lastPart}`;
      }
      return null;
    }
    onChangeField(fieldValue) {
      const processUrl = this.transformToUrl(fieldValue);
      // console.log("processUrl", processUrl);
      // console.log("fieldValue", fieldValue);
      // console.log("this.fieldName", this.fieldName);
      // console.log("this.composerModel", this.composerModel);
      // console.log("this.composerModel.topic", this.topic);
      // console.log("this.composerModel.replyingToTopic", this.composerModel.replyingToTopic);
      // console.log("this.composerModel.action", this.composerModel.currentAction);
      // console.log("this.composerModel.isNew", this.composerModel.isNew);
      // console.log("this.composerModel.editingFirstPost", this.editingFirstPost);
      if (processUrl) {
        this.fieldValue = processUrl;
        this.composerModel.set(this.fieldName, processUrl);
      } else {
        // Handle invalid input - either clear the field or keep the original value
        this.composerModel.set(this.fieldName, null);
        this.fieldValue = null;
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "onChangeField", [_object.action]))();
  }
  _exports.default = TopicCustomFieldComposer;
});