define("discourse/plugins/discourse-topic-custom-fields/discourse/connectors/edit-topic/topic-custom-field-edit-topic", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * type:        step
   * number:      6
   * title:       Show an input in topic title edit
   * description: If your field can be edited by the topic creator or
   *              staff, you may want to let them do this in the topic
   *              title edit view.
   * references:  app/assets/javascripts/discourse/app/templates/topic.hbs
   */

  class TopicCustomFieldEditTopic extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.alias)("siteSettings.topic_custom_field_name")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "composerModel", [(0, _computed.alias)("args.outletArgs.model")]))();
    #composerModel = (() => (dt7948.i(this, "composerModel"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "topic", [(0, _computed.alias)("composerModel.topic")]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "reply", [(0, _computed.alias)("composerModel.replyingToTopic")]))();
    #reply = (() => (dt7948.i(this, "reply"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "canEditTitle", [(0, _computed.alias)("composerModel.canEditTitle")]))();
    #canEditTitle = (() => (dt7948.i(this, "canEditTitle"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "editingFirstPost", [(0, _computed.alias)("composerModel.editingFirstPost")]))();
    #editingFirstPost = (() => (dt7948.i(this, "editingFirstPost"), void 0))();
    constructor() {
      super(...arguments);
      // Only set `fieldValue` if we know we are editing the first post
      if (this.editingFirstPost && this.canEditTitle) {
        this.fieldValue = this.args.outletArgs.model.get(this.fieldName);
      } else {
        // Don't set 'fieldValue' if we are not editing the first post
        this.fieldValue = null;
      }
    }
    onChangeField(fieldValue) {
      // console.log("TopicModel:", this.topicModel);
      // console.log("Can Edit Title:", this.canEditTitle);
      // console.log("Editing First Post:", this.editingFirstPost);
      this.args.outletArgs.buffered.set(this.fieldName, fieldValue);
    }
    static #_8 = (() => dt7948.n(this.prototype, "onChangeField", [_object.action]))();
  }
  _exports.default = TopicCustomFieldEditTopic;
});