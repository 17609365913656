define("discourse/plugins/discourse-topic-custom-fields/discourse/raw-templates/connectors/topic-list-after-title/topic-custom-field-topic-list-after-title", ["exports", "discourse/lib/raw-handlebars", "discourse/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "context.showCustomField", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(2, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 7
          }
        }
      })) != null ? stack1 : "";
    },
    "2": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "  <a href=\"" + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "context.customFieldValue", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 11
          },
          "end": {
            "line": 3,
            "column": 39
          }
        }
      })) + "\" target=\"_blank\" class=\"referenced-button\">\n    <svg xmlns=\"http://www.w3.org/2000/svg\" id=\"svg5\" \n    viewBox=\"0 0 346.76 286.46\" class=\"referenced-icon\">\n      <defs>\n        <style>\n          .cls-1 {\n            stroke: #606f7e;\n          }\n          .cls-1, .cls-2 {\n            stroke-width: 22.68px;\n          }\n          .cls-1, .cls-2, .cls-3 {\n            fill: none;\n            stroke-linecap: round;\n            stroke-linejoin: round;\n          }\n          .cls-2 {\n            stroke: #000;\n          }\n          .cls-3 {\n            stroke: #764d82;\n            stroke-width: 22.68px;\n          }\n        </style>\n      </defs>\n      <g id=\"layer1\">\n        <path id=\"path6303\" class=\"cls-2\" d=\"M335.42,86.9L173.38,11.34,11.34,86.9l162.04,75.56,162.04-75.56Z\"/>\n        <path id=\"path6311\" class=\"cls-1\" d=\"M11.34,124.45l162.04,75.56,162.04-75.56\"/>\n        <path id=\"path6313\" class=\"cls-3\" d=\"M11.34,162.01l162.04,75.56,162.04-75.56\"/>\n        <path id=\"path6315\" class=\"cls-2\" d=\"M11.34,199.56l162.04,75.56,162.04-75.56\"/>\n      </g>\n    </svg>\n  </a>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "unless").call(depth0 != null ? depth0 : container.nullContext || {}, "context.topic.editingTopic", {
        "name": "unless",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 37,
            "column": 11
          }
        }
      })) != null ? stack1 : "";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/connectors/topic-list-after-title/topic-custom-field-topic-list-after-title", template, {
    core: false,
    pluginName: "discourse-topic-custom-fields",
    hasModernReplacement: false
  });
  var _default = _exports.default = template;
});